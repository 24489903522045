.logo {
  margin-top: 6%;
  width: 87%;
  height: 87%;
  object-fit: contain;
  margin-left: -23%;
}

.reservedLogo {
  width: 70%;
  height: 70%;
  object-fit: contain;
  margin-top: 15%;
  margin-left: -22%;
}
