@import "~bootstrap/scss/bootstrap";
@font-face {
  font-family: "Roboto-Regular";
  src: url("../../static/fonts/gotham/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Juventus-Fans-Bold";
  src: url("../../static/fonts/gotham/Juventus-Fans-Bold.ttf")
    format("truetype");
}
