.center-white-busy-room {
  display: flex;
  justify-content: center;
  font-size: 42px;
  font-weight: 700;
  margin: 0px;
}

.sidebar-availability {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rotated-lbl {
  transform: rotate(-180deg);
  align-items: center;
  writing-mode: vertical-rl;
  text-orientation: sideways-right;
  font-family: "Roboto-Regular";
}
