.room-title {
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bolder;
  margin: 0px;
  margin-top: 5%;
}

.big-font-size {
  font-size: 80px;
}

.title-margin-left{
  margin-left: 8%;
}

.title-font-size{
  font-size:78px;
}
