.buttons-container {
  /*display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;*/
  margin: 0 auto;
  width: 890px;
  margin-top: 10%;
}

.layout {
  position: absolute;
  right: 12px;
  left: 12px;
  border: 0px;
  padding: 0px;
  border-collapse: collapse;
  height: 100%;
}

.next-meeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2px;
}

.room-title-container {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
}

.h-10 {
  height: 10%;
}
.h-15 {
  height: 15%;
}
