.center-white {
  color: whitesmoke;
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.h-33 {
  height: 33.5%;
}
.h-27 {
  height: 27%;
}
.h-12 {
  height: 12.5%;
}

.centerTitle{
  justify-content: center;
}

.reservedroom-title-container {
  display: flex;
  align-items: center;
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
  border-left-width: 0px;
}
