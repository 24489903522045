.center-progress-bar {
  justify-content: center;
}
.right-current-time {
  justify-content: right;
}
.left-current-time {
  justify-content: left;
}

.center-progress-bar-items {
  display: flex;
  align-items: center;
  margin-bottom: 10%;
  justify-content: center;
}

.custom-progress-bar {
  width: 100%;
  height: 9px;
  border-width: 2px;
  border-color: rgba(128, 128, 128, 0.3);
  border-style: solid;
  border-radius: 1.5em;
  color: white;
  position: relative;
  margin-bottom: 0px;
}

.custom-progress-bar::before {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  left: 0.5em;
  bottom: 0px;
  top: -1px;
  width: calc(var(--width, 0) * 1%);
  min-width: 1rem;
  max-width: calc(100%-1em);
  border-radius: 1em;
  padding: 3.5px;
}

.custom-progress-bar-dot::before {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  left: calc(var(--left, 1) * 1%);
  bottom: 0px;
  top: -10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 6px;
}

.current-time {
  font-size: 34px;
  font-weight: 400;
  overflow: hidden;
}

.current-days {
  font-size: 28px;
  font-weight: 400;
  overflow: hidden;
}
