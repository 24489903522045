.scheduler {
  overflow-y: hidden;
  max-height: 99.8vh;

  .reduced-height {
    height: 3.4%;
  }

  .meeting-block{
    position: absolute;
    background-color: black;
  }

  .red-line {
    height: 1px;
    width: 100%;
    background-color: red;
    position: absolute;
  }

  .red-triangle {
    width: 0;
    height: 0;
    border-top: 8px solid #0000;
    border-bottom: 8px solid #0000;
    border-left: 8px solid red;
    position: absolute;
  }

  .red-triangle-padding {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .hour-container {
    .divider {
      width: 1px;
      background: #ececec !important;
    }

    .bg-dynamic-color {
      position: relative;
      color: white;
      display: flex;
      //border-top: var(--bs-border-width) var(--bs-border-style)
      //  var(--bs-border-color) !important;
      border-top-left-radius: var(--brd-rad-top, "");
      border-top-right-radius: var(--brd-rad-top, "");
      margin-bottom: var(--mrg-btm, "");
      border-bottom-left-radius: var(--brd-rad-btm, "");
      border-bottom-right-radius: var(--brd-rad-btm, "");
      padding: 0px 0px 0px 6px !important;
      overflow: hidden;
    }

    .sx-position {
      position: relative;
      padding-left: 10px !important;
      align-items: center;
      display: flex;
    }
    .border-top-dotted {
      border-top-style: dotted !important;
    }

    .border-top-bold {
      border-top: 1px solid #aeb3b8 !important;
    }
  }
}
